#filter-mobile {
    /* display: none;*/
}

#filter {
    display: none;
}

.view {
    display: block !important;
}

.hide {
    display: none !important;
}

.closed-icon {
    color: #E60000;
    display: none;
    text-align: right;
    font-size: 30px;
    position: absolute;
    right: 3%;
    top: 103%;
}

.splide__arrows--ttb .splide__arrow {
    display: inline-block;
}

#partners .slick-prev, #partners .slick-next {
    cursor: pointer;
}

#partners .slick-prev:active, #partners .slick-next:active, #partners .slick-prev:hover, #partners .slick-next:hover {
    color: #e60000;
}

/*=================================================================================Mobile=============================================================
    =================================================================================================================================================*/
@media screen and (max-width:766px) {
    .mt-mobile-100 {
        margin-top: 100px;
    }

    #filter-mobile {
        display: none;
    }

    #filter {
        display: flex;
        align-items: center;
    }

    .closed-icon {
        display: inline;
    }

    /*================= filter ================================*/
    .rlr-search-results-page__product-details {
        display: flex;
        flex-direction: column;
        margin-top: -20px;
    }

    /*================= loader ================================*/
    .loader-wrapper.img-gif img {
        width: auto;
    }

    aside.row.rlr-search-results-page__dynamic-filter-section .rlr-js-dynamic-filter-swiper .splide__arrow--next, aside.row.rlr-search-results-page__dynamic-filter-section .rlr-js-dynamic-filter-swiper .splide__arrow--prev {
        color: #fff;
        background-color: #000;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 25px;
        text-align: center;
        margin: auto;
    }

    /*================= header ================================*/
    .rlr-icon-font--megamenu {
        color: #fff;
        font-size: 1.5rem;
        line-height: 1;
    }

    .navigation-header .navigation-brand-text {
        padding: 0;
    }

    .navigation-header, .rlr-navigation__body-header {
        padding-left: 0;
    }

    .rlr-navigation .navigation-body {
        background-color: #000;
    }

    .rlr-navigation .align-to-right {
        display: flex;
        margin-top: 15%;
    }

    .rlr-navigation__body-header {
        padding: 0;
        margin-bottom: 9% !important;
        border: none;
    }

    /*================= widget ================================*/
    .search-form {
        position: relative !important;
        z-index: 10 !important;
        top: 0vh !important;
        height: auto !important;
    }


    .search-form container {
        top: 15px;
    }

    .form-widget {
        top: -11vh;
    }

    .search-form .tab-nav-rounded .nav-link {
        padding: 2px;
    }

    .icon.rlr-icon-font.flaticon-car {
        font-size: 30px !important;
    }

    .search-form .font-size-3 {
        font-size: 1.6rem;
    }

    .search-form .tab-nav-rounded .nav-link.active .icon::before {
        padding: 8px;
    }

    .search-form .font-weight-semi-bold {
        font-weight: 600 !important;
        font-size: 0.7rem;
    }

    #tours .rlr-section-header {
        padding-top: 0rem;
    }

    /*================= visa login ================================*/
    #LoginModule .form-v8-content {
        width: 100% !important;
        margin: 0 !important;
    }

    /*================= main_slider ================================*/
    .main_slider, .main_slider.js_height .slick-list, .main_slider .bg-img:not(.bg-img-tour) {
        height: 80vh !important;

    }

    .main_slider.home {
        margin-bottom: 0vh;
    }

    /*.main_slider .slider_wrap .slide .slide_content {
            bottom: 15vh !important;
            width: 75%;
        }*/
    .main_slider .slider_wrap .slide .slide_content {
        bottom: 33vh !important;
        width: 100%;
    }

    .main_slider .slider_wrap .slide .slide_content p {
        margin-bottom: 0;
    }

    .main_slider .slider_wrap .slide .slide_content .buttons {
        margin-top: 15px;
    }

    .main_slider .slider_wrap .slide .slide_content .title_wrap .slide_title {
        font-size: 30px !important;
    }

    /* .container .row.rlr-product-detail-section {
        margin-top: 50px;
    }*/
    /*================= nav-slider ================================*/
    .rlr-section-header .button-row {
        justify-content: center;
        align-items: center;
        /* position: absolute;
        top: 35vh;
        z-index: 9999999999999;
        right: 25px; */
    }

    nav .rlr-product-detail-secondary-menu__tabitems {
        flex-wrap: wrap;
    }

    nav .rlr-product-detail-secondary-menu__tabitems .rlr-product-detail-secondary-menu__tabitem {
        margin-bottom: 10px;
    }

    /*================= Top Destinations ================================*/
    .destinations {
        padding: 20px 0;
    }

    .destinations_item {
        width: 100%;
    }

    .rlr-section-header>.rlr-section__title {
        text-align: center;
    }

    .destinations .title_wrap {
        text-align: center;
        margin-bottom: 0;
    }

    .rlr-button--brand {
        background-color: transparent;
        color: inherit !important;
        box-shadow: none !important;
        border-bottom: 2px solid #ccc;
        border-radius: unset;
        padding: 0;
    }

    .destinations .title_wrap .controls {
        left: 26%;
        top: 35%;
    }

    .destinations .controls .link:hover {
        text-decoration: none;
    }

    #Destinations .rlr-section__title--main {
        margin-bottom: 10px;
    }

    #Destinations .rlr-section-header>.rlr-section__title {
        margin-bottom: 50px;
    }

    .bg-text {
        width: 75% !important;
        height: auto !important;
    }

    #Destinations .controls .link {
        top: 100%;
        right: 15vw;
        left: unset;
        text-wrap: nowrap;
        top: 14vh;
    }

    #Stories .rlr-section__title {
        margin-bottom: 40px;
    }

    #Stories .controls .link {
        top: 100%;
        right: 27vw;
        left: unset;
        text-wrap: nowrap;
        top: 14vh;
    }

    #rooms .rlr-product-card__image-wrapper, #rooms .swiper, #rooms .swiper-wrapper, #rooms .swiper-slide, #rooms .rlr-product-card__image-wrapper img {
        height: auto !important;
    }
}

/*================= Stories ================================*/
.stories {
    overflow: hidden;
    padding: 40px 0;
}

.rlr-section.rlr-section__mb .rlr-carousel__items .stories .controls .link {
    top: 137%;
    left: -178%;
    border-bottom: 1px solid #0000009e;
}

.rlr-section.rlr-section__mb .rlr-carousel__items .stories .controls .link.dist {
    top: 90%;
    left: -221%;
    border-bottom: 1px solid #0000009e;
}

.rlr-section.rlr-section__mb .rlr-carousel__items .rlr-section-header .rlr-section__title {
    padding-top: 65px;
}

.rlr-section.rlr-section__mb {
    margin-bottom: 0;
}

/*================= Partners ================================*/
#partners {
    padding-bottom: 0px;
    padding-top: 0rem;
}

#partners .slick-prev, #partners .slick-next {
    top: 50%;
    transform: translateY(-50%);
}

/*================= footer ================================*/
.footer {
    margin-top: 45px;
}

.col-12.col-md-10.m-auto.position-relative {
    box-shadow: none !important;
}

/*================= tours-activities ================================*/
@media screen and (max-width:767px) {
    .rlr-search-results-page__breadcrumb-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
    }

    .breadcrumb {
        justify-content: center;
    }

}

.rlr-search-results-header__value strong {
    color: var(--brand);
    text-decoration: none;
    font-weight: 600;
}

.rlr-checkboxes {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.rlr-checkboxes .form-check-block {
    width: 100%;
}

.rlr-product-filters__filters-wrapper {
    padding: 25px 15px;
}

#filter-laptob {
    display: none;
}



#yourFormId aside.row {
    padding: 0 10px;
}

.footer_bottom>div {
    text-align: center !important;
}


/*=================================================================================Tablets=============================================================
    =================================================================================================================================================*/
@media screen and (min-width:768px) and (max-width:1150px) {

    /*================= loader ================================*/
    .main_slider, .slick-slide {
        height: 75vh !important;
    }

    .main_slider.home {
        height: 100vh !important;
        top: 0 !important;
    }

    .tab-display {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

@media screen and (min-width:768px) and (max-width:1100px) {
    .footer_bottom>div {
        text-align: center !important;

    }

    .tab-display {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main_slider .slider_wrap .slide .wrap_float {
        top: -20vh;
    }

    /*================= loader ================================*/
    .main_slider {
        height: 0vh !important;
    }

    .main_slider.home {
        height: 75vh !important;
        top: -0 !important;
        margin-bottom: 0vh;
    }

    .loader-wrapper.img-gif img {
        width: auto;
    }

    .main_slider.home .slider_wrap .slide .slide_content {
        bottom: 10% !important;
    }

    /*================= header ================================*/
    .navigation-body {
        width: 50%;
    }

    .rlr-icon-font--megamenu {
        color: #fff;
        font-size: 1.5rem;
        line-height: 1;
    }

    .navigation-header .navigation-brand-text {
        padding: 0;
    }

    .navigation-header, .rlr-navigation__body-header {
        padding-left: 0;
    }

    .rlr-navigation .navigation-body {
        background-color: #000;
    }

    .rlr-navigation .align-to-right {
        display: flex;
        margin-top: 15%;
    }

    .rlr-navigation__body-header {
        padding: 0;
        margin-bottom: 9% !important;
        border: none;
    }

    /*================= widget ================================*/
    .search-form {
        height: auto;
    }

    .search-form container {
        top: 15px;
    }

    .form-widget {
        position: relative;
        top: -14vh;
    }

    .search-form .tab-nav-rounded .nav-link {
        padding: 5px;
    }

    .search-form .font-size-3 {
        font-size: 1.6rem;
    }

    .search-form .tab-nav-rounded .nav-link.active .icon::before {
        padding: 8px;
    }

    .search-form .font-weight-semi-bold {
        font-weight: 600 !important;
        font-size: 0.7rem;
    }

    /*================= main_slider ================================*/
    .main_slider .slider_wrap .slide {
        position: relative;
        transition: all 0.2s linear;
        height: 100vh;
    }

    .main_slider .slider_wrap .slide .slide_content {
        bottom: 20%;
    }

    .main_slider .arrows {
        display: block;
    }

    .main_slider .arrows .arrow.prev {
        right: 20vw;
        bottom: -45px;
    }

    .main_slider .arrows .arrow.next {
        margin-left: -12vw;
        transform: rotate(180deg);
    }

    .main_slider .slider_wrap .slide .slide_content .title_wrap .slide_title {
        font-size: 50px;
    }

    .main_slider .slider_wrap .slide .slide_content .text {
        font-size: 17px;
    }

    /*================= Top Destinations ================================*/
    .rlr-section-header .button-row {
        position: relative;
        top: 2vh;
        display: flex;
        flex-direction: column-reverse;
        height: 153px;
        justify-content: space-between;
    }

    .destinations_item {
        width: 100%;
    }

    /*================= Stories ================================*/
    .story_item {
        width: 48%;
        height: 400px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        margin-right: 0px;
        border-radius: 13px;
        display: block;
        float: unset;
        margin-bottom: 25px;
    }

    .stories .stries_slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .stories .stries_slider a:first-child {
        margin-left: unset !important;
    }

    .stories .stries_slider a:last-child {
        margin-right: unset !important;
    }

    .stories .dist_slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .stories .dist_slider a:first-child {
        margin-left: unset !important;
    }

    .stories .dist_slider a:last-child {
        margin-right: unset !important;
    }

    .stories .button-row {
        display: none;
    }

    .stories .controls .link {
        font-size: 18px;
        color: #fff;
        width: auto;
        display: block;
        float: left;
        line-height: 44px;
        background-color: #e60000;
        border-radius: 2rem;
        padding: 1rem 2rem;
        left: -86%;
        top: 67%;
        text-wrap: nowrap;
    }

    .rlr-section-header>.rlr-section__title {
        padding-bottom: 40px;
        padding-top: 80px;
        width: 60%;
    }

    #tours .rlr-section-header>.rlr-section__title {
        padding-bottom: 0px;
        padding-top: 0px;
        width: 100%;
    }

    /*===================Destinations=======================*/
    .rlr-search-results-page__product-details {
        flex-direction: column;
    }

    .rlr-checkboxes {
        justify-content: flex-start;
    }

    .rlr-checkboxes .form-check-block {
        width: 30%;
    }

    .rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slogan {
        text-align: center;
    }

    #Destinations.rlr-section__my, #Stories.rlr-section__my {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    #Destinations .rlr-section-header>.rlr-section__title, #Stories .rlr-section-header>.rlr-section__title {
        padding-bottom: 30px;
        padding-top: 30px;
        width: 80%;
    }

    /*===================Cruises=======================*/
    #rooms .rlr-product-card__image-wrapper {
        width: 100%;
        height: 50%;
    }

    /*===================About Travco=======================*/
    .video-icon i {
        right: 0%;
    }

    /*===================News=======================*/
    .blog-standard-section.pt-100.pb-70 .d-none {
        display: block !important;
    }

    /*==================international-offices=======================*/
    .faq-section .filter-ul li {
        width: 33%
    }

    aside.row.rlr-search-results-page__dynamic-filter-section .rlr-js-dynamic-filter-swiper .splide__arrow--next, aside.row.rlr-search-results-page__dynamic-filter-section .rlr-js-dynamic-filter-swiper .splide__arrow--prev {
        color: #fff;
        background-color: #000;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        line-height: 40px;
        text-align: center;
        margin: auto;
    }

    #rooms .rlr-product-card__image-wrapper, #rooms .swiper, #rooms .swiper-wrapper, #rooms .swiper-slide, #rooms .rlr-product-card__image-wrapper img {
        height: auto !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 799px) {
    .form-widget {
        position: relative;
        top: -22vh;
    }

    .search-form {
        height: 1220px;
    }
}

@media screen and (min-width: 810px) and (max-width: 997px) {
    .form-widget {
        position: relative;
        top: -16vh;
    }

    .search-form {
        height: auto;
    }

    .stories .controls .link.dist, .stories .controls .link {
        display: none;
    }
}



/*#region Resposnive Banner Pages */

.tab {
    display: none;
}

.mob {
    display: none;
}

@media screen and (min-width:767px) and (max-width:1100px) {

    .tab {
        display: block;
    }

    .lap {
        display: none;
    }

    .mob {
        display: none;
    }
}

@media screen and (max-width:766px) {
    .room-selector .room-selector-box .room {
        display: flex;
        flex-wrap: wrap;
    }

    .tab {
        display: none;
    }

    .lap {
        display: none;
    }

    .mob {
        display: block;
    }

    #hotel-searcher-_ctl1__ctl1__ctl1_pageBody_pageBody_searcher_ctlMultiSearcher__ctl1_ctlRoomSelector-room-selector-button {
        margin-bottom: 18px;
    }

    .popover-content .form-control {
        position: relative !important;
        height: 60px !important;
        margin-right: 10px;
    }

    .step-bar-list {
        padding-left: 0;
    }

    .step-bar-list .step-bar {
        font-size: 11px;
    }

    .step-bar-list .step-bar .icon-element {
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
        font-size: 14px !important;
    }

    .step-bar-list .step-bar::after, .step-bar-list .step-bar::before {
        top: 15px !important;
    }

    .rlr-main--fixed-top {
        margin-top: 50px;
    }
}

@media screen and (max-width: 768px) {
    #hp {
        width: 100%;
    }
}


@media screen and (min-width: 800px) and (max-width: 1025px) {
    .rlr-navigation .align-to-right {
        margin-top: 0;
    }

    .rlr-navigation .navigation-body {
        background-color: transparent;
        width: 100%;
    }

    .rlr-navigation__body-header {
        margin-bottom: 0 !important;
    }

    .search-form .align-items-end {
        display: block !important;
    }

    .search-form .col-lg-3dot6, .search-form .col-lg-3dot7, .child, .adults, .dates, .durations, .destinationss {
        width: 100% !important;
        max-width: 100% !important;
    }

    .select2.select2-container.select2-container--default .select2-selection.select2-selection--single {
        height: 65px;
    }

    .form-widget {
        position: relative;
        top: -10vh;
    }

    .search-form {
        position: absolute;
        z-index: 10;
        top: 97vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .flex-mobile {
        flex-direction: column;
        align-items: start;
    }

    header .navigation-body.rlr-navigation__body.container-xxxl.scroll-momentum.is-visible {
        background-color: #000;
    }

    .room-selector .room-selector-box .select2, .room-selector .room-selector-box select.form-control {
        width: 70px !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1106px) {

    .search-form {
        height: 1560px;
    }
}

@media screen and (max-width:450px) {
    #Destinations .controls .link, #Stories .controls .link {
        display: none !important;
    }

    .search-form .font-weight-semi-bold {
        font-weight: 600 !important;
        font-size: 0.5rem;
    }

    .main_slider .slider_wrap .slide .slide_content {
        bottom: 37vh !important;

    }

    .main_slider .slider_wrap .slide .slide_content .text {
        font-size: 12px;
    }

    .main_slider .slider_wrap .slide .slide_content .buttons .btn {
        font-size: 12px;
        padding: 0 15px;
        height: 35px;
        line-height: 35px;
    }
}

@media screen and (min-width:768px) and (max-width:800px) {
    .search-form {
        position: absolute;
        z-index: 10;
        top: 100vh;
        left: 50%;
        transform: translateX(-50%);
    }

    .search-form .font-weight-semi-bold {
        font-weight: 500 !important;
        font-size: 10px;
    }

    .main_slider.home {
        margin-bottom: 0vh;
    }

    .main_slider .slider_wrap .slide .slide_content {
        padding-bottom: 170px;
        bottom: 10vh;
    }

    .main_slider .arrows {
        display: none;
    }
}

/*.search-form {

    left: 50%;
    transform: translateX(-50%);
}*/
@media screen and (max-width: 350px) {
    .main_slider.home {
        margin-bottom: 0vh;
    }

    .main_slider .slider_wrap .slide .slide_content {
        bottom: 33.5vh !important;
    }

    .main_slider .slider_wrap .slide .slide_content .title_wrap .slide_title {
        font-size: 22px !important;
    }

    .main_slider .slider_wrap .slide .slide_content .text {
        font-size: 11px;
    }

    .main_slider .slider_wrap .slide .slide_content .buttons .btn {
        font-size: 11px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
    }

    .search-form {
        position: absolute;
        z-index: 10;
        top: 76vh;
        height: auto;
    }

    .rlr-section-header .button-row {
        top: 48vh;
    }
}

@media screen and (max-width:1024px) {
    .nation-icon, .usd-icon {
        position: absolute;
        right: 0;
    }
}