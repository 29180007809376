* {
	box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
}

.rlr-badge--accent-green {
	background-color: #0D1282;
	color: var(--white);
}

.google {
	opacity: 1 !important;
	display: flex;
	justify-content: center;
}

.text-travco {
	color: #e60000 !important;
}

.bg-travco {
	background-color: #e60000 !important;
}

.rlr-product-filters__checkbox.form-check-input+label {
	cursor: pointer;
}

.rlr-popover--hide {
	display: none;
}

.rlr-popover--visible {
	z-index: 9999;
	visibility: visible;
	position: absolute;
	inset: auto auto 0px 0px;
	margin: 0px;
	transform: translate3d(-100%, 50%, 0px);
}

.react-datepicker__day--keyboard-selected {
	/* border-radius: 0.3rem; */
	/* background-color: #bad9f1; */
	/* background-color: transparent !important; */
}

/* 
.fancybox-slide::before {
	content: 'x';
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 30px;
	height: 30px;
	position: absolute;
	background-color: #e60000;
	color: #fff;
	right: 18vw;
	top: 4vh;
	z-index: 1;
	border-radius: 50%;
	cursor: pointer;
}
 */
.video-icon {
	position: relative;
}

.video-icon i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}



.search-form {
	position: relative !important;
	z-index: 10 !important;
	top: 0vh !important;
	height: auto !important;
	margin: auto !important;
	padding: 0 !important;
	left: 0 !important;
	transform: unset !important;

}


.search-form .tab-nav-rounded .nav-link i {
	transition: all 0.5s ease-in-out;
	transform: scaleX(1);
}

.search-form .tab-nav-rounded .nav-link:active i, .search-form .tab-nav-rounded .nav-link:focus i {
	transform: scaleX(-1);
}

.search-form .tab-nav-rounded .nav-link.active .icon::after {

	display: none;
}

#tours .swiper-slide::before {

	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #000000 100%);
	z-index: 1;
	opacity: 0.77;
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 6px;
}

.rlr-button--carousel.dest-button-next, .rlr-button--carousel.tour-button-next {
	background: #e60000;
	color: #fff;
}

#partners .swiper-button-next:after, #partners .swiper-rtl .swiper-button-prev:after, #partners .swiper-button-prev:after, #partners .swiper-rtl .swiper-button-next:after {
	font-size: 30px;
	color: #e60000;
	font-weight: bold;

}

a:hover .rlr-product-card__title {
	color: #e60000 !important;
}

/* Menu  */
@media screen and (min-width: 1024px) {
	#nations.navigation-dropdown {
		width: 210px;
	}
}

body {
	overflow-x: hidden;
}

/* Banner  */

.main_slider .wrap_float {
	position: relative;
	z-index: 1;
	/* height: 100vh;
	overflow: hidden; */
}

.main_slider .slider_wrap .slide {
	width: 100%;
	left: 10%;
}

.main_slider .slick-arrow {
	bottom: 13% !important;
}

.main_slider .slick-arrow .arrow.next {
	right: -1vw;
}

.main_slider .slider_wrap .slide .wrap_float {
	left: -9%;
}

.splide {
	visibility: visible !important;
}

.rlr-banner-splide--fullwidth-v2 .rlr-banner-splide__slide.height-internal-pages {
	width: 100%;

}

.destinations-link:hover {
	color: #e60000;
}

@media screen and (max-width: 1762px) {
	.main_slider .slick-arrow {
		right: 54%;
	}
}

@media screen and (max-width: 1930px) {
	.main_slider .slick-arrow {
		right: 47%;
	}
}

.main_slider .slick-arrow {
	position: absolute;
	z-index: 1000;
	bottom: 42vh;
	right: 55%;
	margin-right: -720px;
	width: auto;
}

@media screen and (max-width: 1762px) {
	.main_slider .slick-arrow .arrow {
		right: 100%;
		bottom: -45px;
	}

}

@media screen and (max-width: 1930px) {
	.main_slider .slick-arrow .arrow {
		right: 100%;
		bottom: -45px;
	}
}

.main_slider .slick-arrow .arrow {
	width: 44px;
	height: 44px;
	border-radius: 50%;
	background: rgba(255, 255, 255, 0.42);
	position: relative;
	transition: all 0.2s linear;
}

/* DatePicker  */
.react-datepicker__header, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
	background-color: #e60000 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name {
	color: #fff !important;
}

@media (max-width: 768px) {
	.rlr-product-filters__filters-wrapper .rlr-checkboxes {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
	}

	.rlr-checkboxes .form-check-block {
		width: 48%;
	}

	.table thead {
		display: none;
	}

	.table, .table tbody, .table tr, .table td {
		display: block;
		width: 100%;
	}

	.table tr {
		margin-bottom: 15px;
	}

	.table td {
		text-align: left;
		padding-left: 10px;
		position: relative;
	}

	/* .table td:before {
		content: attr(data-label);
		position: absolute;
		left: 0;
		width: 50%;
		padding-left: 15px;
		font-weight: bold;
		text-align: left;
	} */

	.table td input {
		width: 100%;
	}
}

.divider {
	border-bottom: 4px solid #e60000;
	opacity: 0.8;
	width: 70px;
	margin: 20px 0 40px 0;
}

.branches {
	position: relative;
}

/* .branches [type=button]:not(:disabled) {
	color: #e60000;
} */

.branches .splide__arrow--next {
	right: 3.5em;
	box-shadow: 0 0 2px 1px #999;
	background-color: #000 !important;
}

.branches .splide__arrow--prev {
	left: 3.5em;
	box-shadow: 0 0 2px 1px #999;
	background-color: #000 !important;
}

@media screen and (max-width: 768px) {

	.rlr-search-results-page__breadcrumb-section .rlr-icon-text {
		box-shadow: 0 0 2px #9999;
		padding: 10px;
		border-radius: 5px;
	}

	.branches .splide__arrow--next {
		right: 1em;
		height: 2.5em;
		width: 2.5em;
		box-shadow: 0 0 2px 1px #999;
	}



	.branches .splide__arrow--prev {
		box-shadow: 0 0 2px 1px #999;
		height: 2.5em;
		width: 2.5em;
		left: 1em;
	}

	.branches .splide__arrow--next i, .branches .splide__arrow--prev i {
		font-size: 25px;

	}

	.rlr-popover--visible {

		transform: translate3d(-87%, 70%, 0px);
	}
}



.search-wrapper {
	width: 66.6%;
	transition: all 0.5s ease-in-out;
	display: block;
	margin: auto;

}

.search-wrapper input {
	border: 1px solid #999;
	border-radius: 80px;
}

.search-wrapper:focus-within {
	width: 100%;
}

.packages .search-wrapper {
	width: 100%;


}

.packages .search-wrapper:focus-within {
	width: 100%;
}

#pills-five-example5 .react-datepicker__day, #pills-five-example5 .react-datepicker__month-text, #pills-five-example5 .react-datepicker__quarter-text, #pills-five-example5 .react-datepicker__year-text {
	background-color: #e7e5e5;
	border-radius: 5px;
	/* box-shadow: 0 0 2px #ccc; */
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
	background-color: #e60000 !important;
	color: #fff !important;
}

#pills-five-example5 .react-datepicker__day--disabled, #pills-five-example5 .react-datepicker__month-text--disabled, #pills-five-example5 .react-datepicker__quarter-text--disabled, #pills-five-example5 .react-datepicker__year-text--disabled {
	background-color: unset;
}

@media screen and (min-width:767px) {
	.packages .search-wrapper {
		width: 75%;


	}

	.packages .search-wrapper:focus-within {
		width: 100%;
	}

	.search-wrapper {
		width: 33.3%;


	}

	.search-wrapper:focus-within {
		width: 66.6%;
	}

	.news-list h3 {
		display: -webkit-box;
		min-height: 90px;

		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.autocomplete-options {
	height: 80vh;
	background-color: #fff;
	z-index: 99999;
	position: absolute;

	overflow-y: scroll;
	width: 26vw;
	padding: 10px;
	box-shadow: 0 0 1px 2px #fcfcfc;
}

.form-control, .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border-color: rgba(28, 35, 31, 0.1) !important;
}

.react-datepicker__week .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
	cursor: not-allowed;
	background-color: unset !important;
	color: #abaaaa !important;
}

/* 
.navigation-transparent .navigation-item>.navigation-dropdown, .navigation-transparent .navigation-megamenu.is-visible {

	overflow: visible !important;
} */

.overflow-x-hidden {
	overflow-x: hidden !important;
}